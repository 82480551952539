import React, { useRef, useEffect } from "react";
import { styled } from "stitches.config";
import CloseIcon from "src/assets/svg/close.svg";

import { useEscapeHandler } from "src/common/components/useEscapeHandler";

const Popup = ({ popupIsVisible, setPopupIsVisible, children, title, Icon, wide, tall }) => {
  const ref = useRef();

  const closePopup = () => {
    setPopupIsVisible(false);
  };

  useEscapeHandler(() => {
    if (popupIsVisible) {
      setPopupIsVisible(false);
    }
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setPopupIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setPopupIsVisible]);

  useEffect(() => {
    if (popupIsVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [popupIsVisible]);

  return (
    popupIsVisible && (
      <PopupContainer>
        <PopupOverlay>
          <PopupContent
            className="row d-flex flex-column flex-nowrap flex-lg-row flex-lg-wrap overflow-auto"
            ref={ref}
            wide={wide}
            tall={tall}
          >
            <div className="icon-wrapper col-24 pt-4 d-flex justify-content-center">
              <div>{Icon && <Icon />}</div>
            </div>
            <div className="col-24">
              <CloseButton onClick={closePopup}>
                <CloseIcon />
              </CloseButton>
              <h3 className="fw-semibold fs-xl mb-4 mt-2 pt-0 text-center">{title}</h3>
              <div className="fs-s my-6 px-4">{children}</div>
            </div>
          </PopupContent>
        </PopupOverlay>
      </PopupContainer>
    )
  );
};

export default Popup;

const PopupContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const PopupOverlay = styled("div", {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "#02020280",
  display: "flex",
  justifyContent: "center",
  zIndex: "999999",
  overflowY: "auto",
  "@lg": {
    paddingTop: "8rem",
  },
});

const PopupContent = styled("div", {
  background: "#fff",
  padding: "20px 0",
  borderRadius: "0px",
  position: "absolute",
  width: "100%",
  maxWidth: "100%",
  zIndex: "9999999",
  "@lg": {
    borderRadius: "20px",
    padding: "20px",
    height: "auto",
    width: "auto",
    maxWidth: "600px",
  },
  variants: {
    wide: {
      true: {
        "@lg": {
          width: "800px",
          maxWidth: "800px",
        },
      },
    },
    tall: {
      true: {
        "@lg": {
          alignContent: "center",
          minHeight: "460px",
          ".icon-wrapper": {
            paddingTop: "0px",
            marginBottom: "24px",
          },
        },
      },
    },
  },
});

const CloseButton = styled("span", {
  position: "absolute",
  top: "18px",
  right: "23px",
  cursor: "pointer",
});
